export default {
  proxy_path: "https://l1l77m407i.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://vm3tx6rci7.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyCIWGqqG3v6U3gNYVZ7NjrcCPBVpx1pfgc",
  main_company_term_id: "6",
  crm_base_url: "https://crm.rossing.forwoodsafety.com",
  forwood_id_url: "https://id.rossing.forwoodsafety.com?redirect_uri=https://mapreport.rossing.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.rossing.forwoodsafety.com",
  Auth: {
    userPoolId: "us-east-1_iprmNd3EW",
    userPoolWebClientId: "58r0jj0btpcn52oq96ttro0dfc",
    cookieStorage: {
      domain: ".rossing.forwoodsafety.com",
      secure: true
    }
  }
};
